import { useWallet } from "@solana/wallet-adapter-react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../redux/store";
import { loginUser } from "../redux/async-actions/userAction";

const SolanaSignIn = () => {
  const dispatch = useAppDispatch();
  const { publicKey, signMessage, connected } = useWallet();
  const user = useSelector((state: RootState) => state.user);

  const signIn = async () => {
    const nonce = Math.floor(Math.random() * 1000000);
    const message = `Sign this message for authenticating with your wallet. Nonce: ${nonce}`;
    const encodedMessage = new TextEncoder().encode(message);

    if (signMessage) {
      const signature = await signMessage!(encodedMessage);

      dispatch(
        loginUser({
          signature: Buffer.from(signature).toString("hex"),
          nonce,
          pubkey: Buffer.from(publicKey?.toBytes() || []).toString("hex"),
          pubkeyString: publicKey?.toString(),
        }),
      );
    } else {
      toast.warning("Please use another wallet! Your wallet does not support signing message.");
    }
  };

  if (user.isAuthenticated) {
    return null;
  }

  // Remove to use signin button
  return null;

  return (
    <button className="btn bg-grad mx-1 h-100" onClick={signIn}>
      Sign-in
    </button>
  );
};

export default SolanaSignIn;
