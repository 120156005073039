import React from "react";
import { MyRouteProps } from "./config/type";

const Home = React.lazy(() => import("./views/Home/Home"));
const CandyMachine = React.lazy(() => import("./views/CandyMachine/CandyMachine"));

const routes: {
  [key: string]: MyRouteProps;
} = {
  home: { name: "HOME", component: Home, path: "/", exact: true },
  // home: { name: "CANDY MACHINE", component: CandyMachine, path: "/", exact: false, loginStatus: "not-required", walletStatus: "not-required", adminRoute: false },
  // candyMachine: { name: "CANDY MACHINE", component: CandyMachine, path: "/candy-machine/:candyMachineAddress", exact: false, loginStatus: "not-required", walletStatus: "not-required", adminRoute: false },
};

export default routes;
