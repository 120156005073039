import React, { useState } from "react";
import routes from "../routes";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { ThemeModeType } from "../config/type";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import SolanaSignIn from "../helpers/SolanaSignIn";
import { useWallet } from "@solana/wallet-adapter-react";

interface AppHeaderProps {
  toggleTheme: () => void;
}

const AppHeader: React.FC<AppHeaderProps> = ({ toggleTheme }) => {
  const { connected, publicKey } = useWallet();
  const [isCollapsed, setCollapse] = useState<boolean>(true);
  const location = useLocation();
  const selectedTheme = useSelector((state: RootState) => state.theme as ThemeModeType);
  const user = useSelector((state: RootState) => state.user);
  const progress = useSelector((state: RootState) => state.progress);

  const handleNavTabs = () => {
    window.screen.width < 1200 && setCollapse(true);
  };

  return (
    <nav
      className={`navbar navbar-expand-xl navbar-dark px-2 pb-0 bg-dark ${
        progress.max !== progress.progress ? "" : "fixed-top"
      }`}
    >
      <div className={`container-fluid`}>
        <a className="navbar-brand" href="/">
          <img className="logo-img" alt="420 Club" src="/assets/logo.png" />
        </a>
        {/* {isCollapsed && (
          <button type="button" className="btn px-3 py-2 nav-link d-none d-xl-grid " onClick={toggleTheme}>
            {selectedTheme === "light" && <i className={`fa-solid fa-lg fa-sun text-warning`} />}
            {selectedTheme === "dark" && <i className={`fa-solid fa-lg fa-moon text-white`} />}
          </button>
        )} */}
        <button
          onClick={() => setCollapse(!isCollapsed)}
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className={"justify-content-end navbar-collapse align-items-center " + (isCollapsed ? `collapse` : ``)}
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav mr-auto my-2 text-center">
            {/* {!isCollapsed && (
              <li className="nav-item text-center d-xl-none">
                <button type="button" className="btn px-3 py-2 mx-auto mx-xl-0 nav-link " onClick={toggleTheme}>
                  {selectedTheme === "light" && <i className={`fa-solid fa-lg fa-sun text-warning`} />}
                  {selectedTheme === "dark" && <i className={`fa-solid fa-lg fa-moon text-white`} />}
                </button>
              </li>
            )} */}

            <li className="nav-item mx-auto d-flex align-items-center">
              {connected && <SolanaSignIn />}
              <WalletMultiButton className="btn btn-gradient py-2 px-4 ms-2" />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default AppHeader;
