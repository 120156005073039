import React, { useState, useEffect } from "react";
import { DISCORD_LINK, TWITTER_LINK } from "../config/constant";

const AppFooter: React.FC = () => {
  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setShowFooter(scrollPosition > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className={`footer ${showFooter ? "show-footer" : ""}`}>
      <div className="container">
        <div className="d-flex justify-content-around w-100 flex-wrap">
          <div className="">
            <h3>
              <img className="img-footer" src="assets/logo.png" style={{ width: "120px" }} alt="Hazed Chameleon" />
            </h3>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <ul className="list-unstyled mb-0">
              <li className="list-inline-item">
                <a href={DISCORD_LINK} target="_blank" rel="noreferrer" className="nav-link mx-2">
                  <i className="fab fa-discord fa-lg" />
                </a>
              </li>
              <li className="list-inline-item">
                <a href={TWITTER_LINK} target="_blank" rel="noreferrer" className="nav-link mx-2">
                  <i className="fab fa-twitter fa-lg" />
                </a>
              </li>
            </ul>
          </div>
          <div className="d-flex align-items-center">
            <a
              href="https://twitter.com/dopefox_grinder"
              className="text-decoration-none text-dark"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-brands fa-square-twitter fa-lg me-1"></i>
              <span className="">POWERED BY DOPEFOX</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppFooter;
